import wideLogoTransparent from './images/wideLogoTransparent.svg';
import './App.css';
import ReactFullpage from "@fullpage/react-fullpage";
import HomePage from "./HomePage";
import {PlatformArchitecture, PlatformDiffs} from "./Platform";
import Services from "./Services";
import UseCases, {EUC, GapFilling, Infrastructure, TraderDesktop} from "./UseCases";
import AboutUs from "./AboutUs";
import Media from "./Media";
import PlatformOverview from "./Platform";
import ContactUs from "./ContactUs";
import Products from "./Products";
import {useState} from "react";



const SECTIONS = {
    home: "Home",
    platform: "The Platform",
    products: "Products",
    services: "Services",
    usecases: "Use Cases",
    media: "Media",
    about: "About Hudson",
    contact: "Contact Us"
}
const ANCHORS = Object.keys(SECTIONS);
const NAMES =Object.values(SECTIONS);

const Navbar =(props) => {
    return (
        <div id={'navbar'}>
            <div>
                <a href={'#home'}>
                    <img style={{height:'100%'}} src={wideLogoTransparent} alt={"Hudson Fintech Limited"}/>
                </a>
            </div>

            <div className={'navButtons'}>
                {ANCHORS.map((section, idx) => {
                    if (idx>0) return <a className={'navButton'} key={'section'+idx} data-menuanchor={section} href={'#' + section}>
                        <div key = {section+"div"+idx}>{SECTIONS[section]}</div>
                    </a>
                    else return <></>
                })}
            </div>

        </div>
    )
}



function App() {
  const [formMessage, setFormMessage] = useState();

  return (
    <div className="App">
        <Navbar/>
      <ReactFullpage
          //fullpage options
          licenseKey = {'8936888C-FE9D49BA-97D08A59-B8AE7501'}
          scrollingSpeed = {700} /* Options here */
          slidesNavigation={false}
          controlArrows={false}
          // normalScrollElements={'.offering-card'}
          lazyLoading={true}
          navigation
          showActiveTooltip={false}
          navigationTooltips = {NAMES}
          paddingTop={"0px"}
          loopBottom = {true}
          menu={'#navbar'}
          anchors={ANCHORS}
          onLeave={(origin, dest) => {
              if (!dest.isLast && origin.isLast) {
                  setFormMessage("");
                  console.debug("hide Footer");
                  const collection = document.getElementsByClassName("footer");
                  Array.from(collection).forEach((element)=> {
                      setTimeout(()=>element.classList.remove("show"), 0);
                      setTimeout(()=>element.classList.add("hide"), 0);
                  });
              }
          }}
          afterLoad={(origin, dest)=>{
              if (dest.isLast && !origin.isLast) {
                  console.debug("Show Footer");
                  const collection = document.getElementsByClassName("footer");
                  Array.from(collection).forEach((element)=> {
                          setTimeout(()=>element.classList.remove("hide"), 0);
                          setTimeout(()=>element.classList.add("show"), 0);
                  });
              }
          }}
          render={({ state, fullpageApi }) => {
            return (
                <ReactFullpage.Wrapper>
                  <div className="section">
                      <HomePage api={fullpageApi} setFormMessage={setFormMessage}/>
                  </div>

                  <div className="section">
                      <div className={"slide"}>
                          <PlatformOverview api={fullpageApi}/>
                      </div>
                      <div className={"slide"}>
                          <PlatformDiffs api={fullpageApi}/>
                      </div>
                      <div className={"slide"}>
                          <PlatformArchitecture api={fullpageApi}/>
                      </div>
                  </div>
                    <div className="section">
                        <Products api={fullpageApi} setFormMessage={setFormMessage}/>
                    </div>
                    <div className="section">
                        <Services/>
                    </div>

                    <div className="section">
                        <div className={"slide"}>
                            <UseCases api={fullpageApi}/>
                        </div>
                        <div className={"slide"}>
                            <GapFilling api={fullpageApi}/>
                        </div>
                        <div className={"slide"}>
                            <EUC api={fullpageApi}/>
                        </div>
                        <div className={"slide"}>
                            <Infrastructure api={fullpageApi}/>
                        </div>
                        <div className={"slide"}>
                            <TraderDesktop api={fullpageApi}/>
                        </div>

                    </div>

                    <div className="section">
                        <Media/>
                    </div>

                    <div className="section">
                        <AboutUs/>
                    </div>

                    <div className="section">
                        <ContactUs formMessage={formMessage}/>

                    </div>
                    <div className={'footer hide'}>
                        <span>HUDSON FINTECH LIMITED</span>
                        <span>© copyright 2022 hudson fintech ltd</span>
                        <span>company number: 11914769</span>
                        <span> View Our <a href="./hudson-terms.html" target="_blank"> Privacy And Cookie Policy</a> </span>
                    </div>
                </ReactFullpage.Wrapper>
            );
          }}
      />
    </div>
  );
}

export default App;
