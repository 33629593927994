import {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {FcBusinessman, FcDepartment, FcFeedback, FcFlowChart} from "react-icons/fc";
import {ImLinkedin} from "react-icons/im";

const URL = "https://amrql8zdl4.execute-api.eu-west-1.amazonaws.com/ProductionWebsite/contact-us";

const ContactUs = (props) => {
    return <>
        <div className={'Row'}>
            <span className={'Attributes'} >
                Contact Us
            </span>
        </div>
        <div className={'space'}/>
        <div className={'Row prose'}>
            <span>We welcome any and all questions and would love to arrange a phone-call or demo to discuss what Hudson can do for you.
                Please use the form below to get in touch today </span>
        </div>
        <div className={'Row'}>
            <ContactForm formMessage = {props.formMessage} submitLabel = {'Send Message'} source='ContactUs' onSubmit={() => {
                alert("Thank-you. We will get back to you shortly");}
            }/>

        </div>
        <div className={'Row'}>
            <div style={{fontSize:'small', maxWidth:'70vw'}}>
                By submitting this form you agree that Hudson Fintech may store and process the information that you
                have provided for our internal use only. Further, you agree that Hudson Fintech may contact you in
                relation to the message. Hudson Fintech will never sell or share your private data with any third
                parties. For more information please review our <a href="./hudson-terms.html" target="_blank">Privacy Policy</a>.
            </div>
        </div>
        <div className={'Row'} style={{paddingTop:'50px'}}>
            <a target="_blank" href="https://www.linkedin.com/company/hudson-fintech-ltd" title="linkedin" rel="noreferrer">
                <ImLinkedin size={'40px'}/>
            </a>
        </div>
    </>
}

export const ContactForm = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [botFodder, setBotFodder] = useState();

    useEffect(()=> {
            setMessage(props.formMessage);
    }, [props.formMessage]);

    const submit = () => {
        if (botFodder) {
            alert ("Invalid form submission - You have been identified as a bot");
            return;
        }

        const nameRe = /[A-Za-z]{1}[A-Za-z]/;
        if (!nameRe.test(firstName)) {
            alert ("First Name can not less than 2 char");
            return;
        }

        if (!nameRe.test(lastName)) {
            alert ("Last Name can not less than 2 char");
            return;
        }

        if (!email) {
            alert ("Please enter your email id");
            return;
        }

        let emailRe = /^([\w-.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (!emailRe.test(email)) {
            alert ("Please enter valid email address");
            return;
        }

        if (!message || message.length < 10) {
            alert("Please include a descriptive message for us");
            return;
        }

        let data;
        if (props.source ==='Whitepaper') {
          data = {
              source: "whitepaper",
              firstname : firstName,
              surname : lastName,
              company : company,
              jobtitle : title,
              email : email,
              message : message
          }  
        } else {
            data = {
                name: firstName + " " + lastName,
                company: company,
                email: email,
                message: message
            };
        }

        fetch(URL, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(()=>{
            props.onSubmit();
            setFirstName("");
            setLastName("");
            setEmail("");
            setMessage("");
            setCompany("");
            setTitle("");
        }).catch(()=>{
            alert("Sorry, we were unable to submit the message. Please contact us on info@hudsonfintech.com");
        });

    }

    return <>
            <Form style={{margin:'10px'}}>
                <Row style={{visibility:'hidden', height: '2px'}}>
                    <Form.Group className="mb-3" controlId="tph">
                        <InputGroup>
                            <InputGroup.Text><FcFeedback/></InputGroup.Text>
                            <Form.Control type="text" name='telephone'
                                          placeholder="Leave This field blank - it's only here to fool spambots" value={botFodder} onChange={(e)=>setBotFodder(e.target.value)}/>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="FirstName">
                            <InputGroup>
                                <InputGroup.Text><FcBusinessman/></InputGroup.Text>
                                <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="LastName">
                            <InputGroup>
                                <InputGroup.Text><FcBusinessman/></InputGroup.Text>
                                <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="CompanyName">
                            <InputGroup>
                                <InputGroup.Text><FcDepartment/></InputGroup.Text>
                                <Form.Control type="text" placeholder="Company Name" value={company} onChange={(e) => setCompany(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="JobTitle">
                            <InputGroup>
                                <InputGroup.Text><FcFlowChart/></InputGroup.Text>
                                <Form.Control type="text" placeholder="Job Title" value={title} onChange={(e)=> setTitle(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="Message">
                        <InputGroup>
                            <Form.Control style={{height: '100px'}} as="textarea"
                                          placeholder="Please provide a short message about yourself and your interest in Hudson"
                                          value={message} onChange={(e)=>setMessage(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="Email">
                            <InputGroup>
                                <InputGroup.Text ><FcFeedback/></InputGroup.Text>
                                <Form.Control type="email" placeholder="Enter email address" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                            </InputGroup>
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Button style={{width: '100%'}} onClick={submit}> {props.submitLabel} </Button>
                    </Col>
                </Row>
            </Form>
    </>
}

export default ContactUs;