import {Button, Card, Modal} from "react-bootstrap";
import troy from './images/portraits/troy.jpg';
import dietmar from './images/portraits/dietmar.jpg';
import mike from './images/portraits/michael.jpg';
import martin from './images/portraits/martin.jpg';
import bayMap from './images/bay_map.webp';
import {useState} from "react";

const AboutUs = (props) => {
    const [showName, setShowName] = useState(false);

    return <div className={'Column'} style={{width:'100vw', paddingTop:'75px'}}>
        <div className={'Row'} style={{width:'100vw'}}>
        <span className={'Attributes'} >
                                      Hudson Knows Finance
             </span>
        </div>
        <div className={'Row'} style={{width:'90vw', margin:'5vw'}}>
            <div className={'prose'}>
            Hudson Fintech was formed in 2019 to bring new and innovative solutions to financial institutions with an
            ethos of high agility and flexibility to address shortcomings in current systems. Our core concept was based on
            bringing the features and functionality available in video game engines to the world of finance with a
            platform that can deliver high performance applications with unparalleled flexibility to adapt to changing
            markets. Our management team brings together professionals from across capital markets with decades of
                experience.
            </div>
        </div>
        <div className={'Row'} style={{width:'100vw', justifyContent:'center', flexWrap:'wrap',flexShrink:1}}>
            <Person name={"Troy Peterson"} title = {"Co-Founder"} image={troy} profile={"https://www.linkedin.com/in/troy-peterson-3a449015/"}/>
            <Person name={"Dietmar Nowatschek"} title = {"Co-Founder"} image={dietmar} profile={"https://www.linkedin.com/in/hudsonfintech/"}/>
            {/*<Person name={"Michael Walliss"} title = {"Executive Chairman"} image={mike} profile={"https://www.linkedin.com/in/michael-walliss-95a2aa4/"}/>*/}
            <Person name={"Martin Best"} title = {"Head of Business Development"} image={martin} profile={"https://www.linkedin.com/in/martin-best-a126751a/"}/>
        </div>
        <div className={'Row'} style={{width:'90vw', margin:'5vw', cursor:'pointer'}} onClick={()=>setShowName(true)}>
            Click here to learn more about our name
        </div>
        <Modal show={showName} onHide={()=>setShowName(false)} size={"lg"}>
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    What's in a name?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Intrepid Explorers</h4>
                <div>
                    <div className={'prose'}>
                        Our company's name comes from the intrepid 16th century explorer Henry Hudson who opened up much
                        of the New World. Hudson explored all along the east coast of Canada and the United States and
                        has many geographic features named after him including Hudson Bay, one of the most striking
                        geographic features visible from space and the Hudson River which flows through the heart of the
                        world's largest financial center. With our company values of innovation, agility, and
                        determination we hope to reflect the intrepidity of Henry Hudson and his unswerving drive
                        forward in our business. Our logo of the ship reflects this commitment. We hope your partnership
                        with Hudson Fintech will be a journey that will open up the world to your business.
                    </div>
                    <Modal.Footer>

                    <img style={{maxHeight:'450px', marginRight:'auto'}} src={bayMap} alt={"Map of Hudson's Bay"}/>
                        <Button onClick={()=>setShowName(false)}>Close</Button>
                    </Modal.Footer>

                </div>
            </Modal.Body>
        </Modal>

    </div>
}

const Person = (props) => {
    return <Card style={{maxWidth:'11vw', minWidth:'150px', margin:'5px'}}>
        <Card.Img variant={'top'} data-src={props.image} alt={props.name}/>
        <Card.Body>
            <Card.Title>
                {props.name}
            </Card.Title>
            <Card.Subtitle>
                {props.title}
            </Card.Subtitle>
            <Card.Link target="_blank" href={props.profile}>View Profile</Card.Link>
        </Card.Body>
    </Card>
}

export default AboutUs;