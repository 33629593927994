import hero from './images/HudsonHero.webp';
import {Button} from "react-bootstrap";

const HomePage = (props) => {
    return <div className={'Row'}>
        <div className={'Column'}>
            <div style={{marginLeft:'10%'}}>
                                  <span className={'Attributes'} >
                                      Powerful &#9679; Flexible &#9679; Affordable
                                  </span>
                <div>
                    <h1 className={'section-heading'}>
                        Hudson Edge <br/>
                        Enterprise Application Platform
                    </h1>
                </div>
                <span><p>Integrated application platform and low-code development environment
                    for powering demanding financial applications and workflows </p>
                    <p>Hudson Fintech is <i>your</i> build partner to realise <i>your vision</i> for the future</p>
                </span>

                <div className={'button-row'}>
                    <Button variant="primary" onClick={(e) => {
                        e.preventDefault()
                        props.setFormMessage("I'm interested in booking a demo of Hudson.");
                        props.api.moveTo(8);
                    }}>
                        REQUEST A DEMO
                    </Button>
                    <div className={'space'}/>
                    <Button variant="outline-secondary"  onClick={(e) => {
                        e.preventDefault()
                        props.api.moveTo(6)
                        //props.api.moveSectionDown();
                    }}>
                        LEARN MORE
                    </Button>
                </div>
            </div>
        </div>
        <div className={'Column'}>
            <img data-src={hero} alt={"Hudson modularity"}/>
        </div>
    </div>
}

export default HomePage;