import collaborationImage from './images/collaboration.webp';

const Services = (props) => {
    return <div className={'Row'}>
        <div className={'Column'}>
            <div style={{marginLeft:'10%'}}>
                                  <span className={'Attributes'} >
                                      Hudson as a Build Partner
                                  </span>
                <div className={'space'}/>
                <div className={'prose'}>
                    Hudson Fintech are experts in modern agile development for the financial industry across all product lines,
                    including Repo / Securities Finance, Swaps, Electronic Bond Trading, and more.
                    We can supply business and software expertise for projects big and small including consultation and
                    development services both with the Hudson Edge platform and on your incumbent systems. Talk to Hudson
                    to help identify areas of cost-savings, risk-reduction, or regulatory compliance where the Hudson Edge
                    platform may benefit your business.  Our team brings with them extensive experience and knowledge along with
                    a complete toolkit of innovative and powerful solutions.
                </div>
<br/>
                <div className={'prose'}>
                    Whatever your needs, your engagement with Hudson will be a partnership. We will work closely with you
                    at every step to ensure that no requirements are missed and no opportunities are lost, delivering the
                    end result to your precise requirements without compromise.
                </div>
            </div>
        </div>
        <div className={'Column'}>
            <img data-src={collaborationImage} alt={"Team working together"}/>
        </div>
    </div>
}

export default Services;