import './slideshow.css';
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, ListGroup} from "react-bootstrap";
import BasicFormImage from './images/BasicFormImage.webp';
import HudsonFormImage from './images/HudsonForms.webp';
import GameDeveloper from './images/game_developer.webp';
import Gamer from './images/gamer_girl.webp';
import {FiArrowLeft} from "react-icons/fi";
import {FiArrowRight} from "react-icons/fi";
import WhitePaperForm from "./Whitepaper";

import BlotterImage from './images/features/Blotter.webp';
import UI_FeatureImage from './images/features/UI_Feature.webp';
import WFImage from './images/features/workflowEditor.webp';
import FormEditorImage from './images/features/FormEditor.webp';
import EdgeSheetsImage from './images/features/EdgeSheets.webp';
import DevToolsImage from './images/features/DevTools.webp';

const slideImages = [
    {image: UI_FeatureImage, desc: "Intuitive and powerful modern User Interface"},
    {image: BlotterImage, desc: "Industry leading blotters and charts"},
    {image: WFImage, desc: "Graphical Workflow Editor to design behaviour and functionality"},
    {image: FormEditorImage, desc: "Screen Editor to create powerful and dynamic custom forms"},
    {image: EdgeSheetsImage, desc: "Convert desktop spreadsheets into powerful applications automatically with 'EdgeSheets'"},
    {image: DevToolsImage, desc: "Rapidly design and build reliable, high performance applications with little or no code"}
];

const PlatformOverview = (props) => {
    const slideShowColumn = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideTimer = useRef();
    //const slider = useRef();

    const startTimer = () => {
        if (slideTimer.current) clearInterval(slideTimer.current);
        console.debug("starting slideshow timer")
        slideTimer.current = setInterval(() => {
            setCurrentSlide((previous) => previous<slideImages.length-1?previous+1:0);
        }, 5000);
    }

    const stopTimer = () => {
        if (slideTimer.current) {
            console.debug("stopping slideshow timer")
            clearInterval(slideTimer.current);
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    startTimer()
                } else {
                    stopTimer();
                }

            }, { rootMargin: '0px' }
        );

        slideShowColumn.current && observer.observe(slideShowColumn.current);
        const cleanupElement = slideShowColumn.current

        return () => observer.unobserve(cleanupElement);
    }, []);

    useEffect(()=> {
        // Make all other slides invisible
        const collection = document.getElementsByClassName("slide-image");

        Array.from(collection).forEach((element)=> {
            if (element.id !== 'slide' + currentSlide) {
                setTimeout(()=>element.classList.remove("slide-image-visible"), 0);
                setTimeout(()=>element.classList.add("slide-image-hidden"), 0);
            }
        });

        // Add slide in animation to the new slide
        const newSlideElement = document.getElementById("slide" + currentSlide);
       if (newSlideElement) {
           setTimeout(()=> {
               newSlideElement.classList.remove('slide-image-hidden');
               newSlideElement.classList.add("slide-image-visible")
           }, 0);
       } // .className = 'slide-image animated';



    }, [currentSlide]);

    useEffect(()=> {
        const collection = document.getElementsByClassName("active-feature");
        Array.from(collection).forEach((element)=> {
            element.className = '';
        });
        const activeElement = document.getElementById('slideText'+currentSlide);
        if (activeElement) activeElement.className='active-feature';
    }, [currentSlide]);



    return <div className={'Row'}>
        <div className={'Column'} ref={slideShowColumn}>
            {slideImages.map((slideImage, index)=> (
                <img key={"slideImage" + index} id={"slide" + index}
                     className={"slide-image slide-image-hidden "}
                     src={slideImages[index].image} alt={slideImages[index].desc} width={'100%'}
                     onMouseEnter={stopTimer} onMouseLeave={startTimer}
                />
            ))}

        </div>
        <div className={'Column'}>
            <div style={{marginLeft:'10%'}}>
                                  <span className={'Attributes'} >
                                      Turbocharge your team
                                  </span>
                <div className={'prose'} style={{backgroundColor:'#f6fbffEE', zIndex:999}}>
                   The Hudson Edge Platform is an Enterprise low-code solution designed specifically for finance.
                    Hudson edge enables firms to rapidly create and deploy applications to end users using an array of
                    integrated, rapid-development features. With Edge you can create and deploy applications to fill
                    functional gaps in your existing systems, create powerful and dynamic data flows and links between
                    you applications, extend the functionality of your current systems, or even built complete new business
                    applications for analytics or trading. Hudson Edge brings true agile development to your team and business.
                </div>
                <div/>
                <ListGroup  variant="flush" >
                    {slideImages.map((slideImage, index)=> (
                        <ListGroup.Item action key={'slideText'+index} active={index === currentSlide}
                                        onClick={()=>{ setCurrentSlide(index); startTimer();}}
                        >
                            {slideImage.desc}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'end', margin: '5px'}}>
                <Button onClick={()=> {props.api.moveSlideRight()}}>Learn How We Are Different<FiArrowRight/></Button>
                </div>
                {/*<ul className={'featureList'}>*/}
                {/*    {slideImages.map((slideImage, index)=> (*/}
                {/*        <li id={'slide'+index} onClick={()=>{slider.current.goTo(index)}}>{slideImage.desc}</li>*/}
                {/*    ))}*/}
                {/*</ul>*/}
            </div>
        </div>
    </div>
}

const PlatformDiffs = (props) => {
    return <>
        <div className={'Row'}>
        <span className={'Attributes'} >
            Edge is <i>Different</i>
        </span>
        </div>
    <div className={'Row'}>
        <Card className={'offering-card'} style={{width: '40vw', height:'70vh'}}>
            <Card.Header>Other 'Low Code' Platforms</Card.Header>
            <Card.Img variant="top" src={BasicFormImage} alt={"Create Simple Webforms only"}/>
            <Card.Body style={{overflow:'auto'}}>
                <ul>
                    <li>Create basic web forms</li>
                    <li>Requires complex data modelling</li>
                    <li>Limited Toolkit of generic components</li>
                    <li>Only basic data tables</li>
                    <li>Targeted towards small applications</li>
                    <li>No failover, monitoring, reliability, or auditing</li>
                    <li>Limited ability to interact with markets or live data</li>
                    <li>Based on outdated architecture</li>
                    <li>Applications are difficult to update</li>
                    <li>Not suitable for financial markets</li>
                </ul>
            </Card.Body>
        </Card>
        <Card className={'offering-card'} style={{width: '40vw', height:'70vh'}}>
        <Card.Header>Hudson Edge Platform</Card.Header>
        <Card.Img variant="top" src={HudsonFormImage} alt={"Create complex and powerful screens"} />

        <Card.Body style={{overflow:'auto'}}>
            <ul>
                <li>Create powerful custom screens with ease</li>
                <li>Dynamic flexible datamodel</li>
                <li>Toolkit includes many tools for finance in addition to basic components</li>
                <li>Powerful financial blotters with pivots and charts</li>
                <li>Quickly build simple applications and scales up to entire trading platforms</li>
                <li>Hot-Hot failover support, distributed data cache, high-availability</li>
                <li>Detailed logging and high precision auditing to meet regulatory requirements </li>
                <li>Realtime, high-throughput transactions and updates</li>
                <li>Complete API for custom extensions</li>
                <li>Import Excel spreadsheets to create applications automatically</li>
                <li>Deploy updates without server restarts</li>
                <li>Based on advanced Entity-Component-System architecture</li>
            </ul>

        </Card.Body>
    </Card>
    </div>
    <div className={'Row'}>
        <div className={'Column'}>
            <Button style={{width:'150px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>Go Back</Button>
        </div>
        <div className={'Column'} >
            <div style={{ display:'flex', justifyContent:'end'}}>
            <Button style={{width:'450px'}}  onClick={() => props.api.moveSlideRight()}>Learn About Our Unique Architecture <FiArrowRight/></Button>
            </div>
        </div>
    </div>
    </>
}


const PlatformArchitecture = (props) => {
    const [showWhitePaperForm, setShowWhitePaperForm] =useState(false);
    return <> <div className={'Row'}>
        <span className={'Attributes'} >
            Advanced <em>Engine</em> Technology
        </span>
        </div>
        <div className={'Row'}>
            <p className={'Column prose'}>
                The concept of the Hudson Edge Platform derives from video game technology as seen on modern desktop, mobile,  and
                console games. With a game engine a game designer has a well engineered and tested framework upon which to build
                their vision. With the hard stuff already taken care of, like the 3d graphics maths, sound, and input handling
                a designer can jump in with a set of tools catered towards their needs to build a game without having to
                understand the technicalities of matrix math, audio waveform mixing, or controller multiplexing. This enables
                artists, musicians, and visionaries to build the game themselves instead of relying on programmers to make
                it happen. While the engineers who know the maths and understand the hardware intricacies can work on continually
                improving the performance and reliability of the engine.
            </p>
            <div className={'Column'}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img style={{maxHeight:'30vh'}} src={GameDeveloper} alt={"Game Developers at work"} />
                </div>
            </div>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img style={{maxHeight:'30vh'}} src={Gamer} alt={"Gamer enjoying the result"}/>
                </div>
            </div>
            <p className={'Column prose'} >
                Game designers don't start from scratch, and neither should you. Hudson Edge provides a powerful and robust
                platform with auditing, security, persistence, and reliability 'baked-in'. All applications built on Hudson,
                big or small, immediately reap these benefits. On top of that, our visual tools such as the form editor or
                workflow designer allow business analysts and other team members to build or modify the software at an unparalleled
                agile pace without compromise. The core of this is our Entity-Component-System (ECS) architecture, which is a design
                architecture borrowed from game engines that enables easy plug-in of modules, solid testing, and dynamic handling of
                massive amounts of data without a rigid pre-defined data model.
                <Button onClick={()=>setShowWhitePaperForm(true)}>Read our Whitepaper For more on ECS Architecture </Button>
                <WhitePaperForm show={showWhitePaperForm} onClose={() =>{setShowWhitePaperForm(false)}} />
            </p>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <Button style={{width:'150px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>Go Back</Button>
            </div>
            <div className={'Column'}/>
        </div>


    </>;
}

export default PlatformOverview;
export {PlatformDiffs}
export {PlatformArchitecture}
