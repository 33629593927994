import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {useState} from "react";
import WhitePaperForm from "./Whitepaper";

const Media = (props) => {
    const [showWhitePaperForm, setShowWhitePaperForm] = useState(false);
    return <div className={'Column'} style={{}}>
        <div className={'Row'} style={{width:'100vw'}}>
            <span className={'Attributes'} >
                Learn more about the Hudson 'Edge'
             </span>
        </div>
        <div className={'Row'}>
        <span  style={{maxWidth:'55vw', margin:'10px'}}>Hudson is the Enterprise Low-Code Solution for Finance. Find out what that means and what Hudson can do for you. Please <a href={'#contact'}>contact us</a> for a discussion of your needs and how we can help.</span>
        </div>
        <div className={'Row'}>
        <iframe style={{width:"45vw", height:"50vh"}}
                title={"Introduction To Edge"}
                src="https://www.youtube.com/embed/-3FARSpFhsg?modestbranding=1&cc_load_policy=1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>

        </div>

        <div className={'Row'} style={{marginTop: '1vh'}}>
            <Button onClick={()=>{setShowWhitePaperForm(true)}}>Download Our Whitepaper</Button>
        </div>
        <WhitePaperForm show={showWhitePaperForm} onClose={() =>{setShowWhitePaperForm(false)}} />
    </div>
}


export default Media;