import {Button} from "react-bootstrap";
import './UseCases.css';
import {BsChevronRight} from "react-icons/bs";
import GapFillingImage from './images/gapFilling.webp';
import SpreadsheetImage from './images/spreadsheets.webp';
import InfrastructureImage from './images/infrastructure.webp';
import TraderImage from './images/trader.webp';
import {FiArrowLeft, FiArrowRight, FiHome} from "react-icons/fi";


const UseCases = (props) => {
    return <>
        <div className={'Row'} style={{width:'100vw'}}>
            <span className={'Attributes'} >
                How Hudson Edge Can Work For You
            </span>
        </div>
        <div className={'Row'} style={{width:'90vw'}}>
            <div className={'Column prose'} style={{width:'80vw'}}>
                The Hudson Edge Platform is ideal for rapidly bringing to life solutions to your business problems. Using the
                vast toolbox provided it is possible to build and deploy highly robust applications in a fraction of the time
                that it would take with traditional tools. It quickly becomes highly cost effective to streamline all of your firm's
                processes by developing and deploying time and cost-saving measures that would not be worth the effort otherwise.
                <br/><br/>
                The Hudson Edge Platform brings performance, reliability, maintainability, access control, supportability,
                and audit trail logging to any task that it is applied to.
            </div>
        </div>
        <div className={'space'}/>
        <div className={'Row'}>
            <em>Click on the sections below to explore some of the many ways that Hudson Edge can save you money and improve your business</em>
        </div>
        <div className={'Column'} style={{alignItems:'center', width:'100%'}}>
            <OfferingGroup name={"Gap-Filling Solutions"} description={"Meet functional shortfalls in your current systems"}
                           onClick = {() => props.api.moveTo(5,1)}/>
            <OfferingGroup name={"End-User Computing"} description={"Replace high-risk desktop deployed tools with a centralised application"}
                           onClick = {() => props.api.moveTo(5,2)}/>
            <OfferingGroup name={"Infrastructure Enhancement"} description={"Improve reliability and capabilities of batch jobs"}
                           onClick = {() => props.api.moveTo(5,3)}/>
            <OfferingGroup name={"Trader Desktop"} description={"Build high performance trader applications"}
                           onClick = {() => props.api.moveTo(5,4)}/>
        </div>

    </>
}

const OfferingGroup = (props) =>{
    return <div className={'OfferingGroup'} style={{width:'80vw'}} onClick={props.onClick}>
        <div className={'OfferingGroupTitle'}>{props.name}</div>
        <div className={'OfferingGroupDescription '}>{props.description}</div>
        <div className={'OfferingChevron'}>
            <BsChevronRight size={'4em'}/>
        </div>
    </div>
}


const GapFilling = (props) => {
    return <>
       <div className={'Row'}>
            <span className={'Attributes'} >
                Meet functional shortfalls in your current system
            </span>
       </div>
        <div className={'Row'}>
        <div className={'Column'}>
            <img src={GapFillingImage} alt={"Filling gaps in existing software"} />
            You use expensive proprietary software from big vendors in your firm - yet there is always something missing.
            Perhaps your trading system has a downstream feed but doesn't support the settlement backend that you use,
            or it is missing a way for the sales team and traders to interact in a recorded and structured way. It might
            be missing a simple calculation that you need or the ability to handle less common variations of common trade
            types.  Big vendors are very slow to adapt to market changes and regulations and usually charge huge fees for
            even simple new feature requests and customisations. With Hudson Edge these things can be implemented in
            a fraction of the time that it would take your vendor to responds, and for a fraction of the price and we can
            use any integration points available to make it as seamless as possible.
        </div>
        <div className={'Column'}>
            <h1>Examples</h1>
            <ul>
                <li>
                    Trade Hospital
                    <ul>
                        <li>
                            Intercept trade messages between systems to catch and correct errors before it's too late
                        </li>
                        <li>
                            Avoid charges for settlement by applying intelligence to avoid sending trades that do not need
                            to settle, such as place-holders or shell trades
                        </li>
                    </ul>
                </li>
                <li>
                    Sales Trader Workflow
                    <ul>
                        <li> Design and build complex workflows between the sales and trading desks with full auditing </li>
                        <li> Support multiple asset classes and trade types on a single platform </li>
                        <li> Implement complex rules for routing, approvals and negotiation </li>
                    </ul>
                </li>
                <li>
                    Build workflows to handle situations that you current system cannot
                    <ul>
                        <li>Exotic calendars such as Ethiopian 13-month</li>
                        <li>New Regulations</li>
                    </ul>
                </li>
                <li>
                    Custom validation rules
                    <ul>
                        <li>Credit and availability checks across systems</li>
                        <li>Know-your-client checks</li>
                        <li>Maintain lookups for sanctions or corporate policies</li>
                    </ul>
                </li>
                <li>
                   Alternate trade structures
                    <ul>
                        <li>Handle Bond vs Bond REPO</li>
                        <li>Handle Evergreen rolling structures</li>
                    </ul>
                </li>
            </ul>
        </div>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <Button style={{width:'150px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>Go Back</Button>
            </div>
            <div className={'Column'} >
                <div style={{ display:'flex', justifyContent:'end'}}>
                    <Button style={{width:'300px'}}  onClick={() => props.api.moveSlideRight()}>End User Computing <FiArrowRight/></Button>
                </div>
            </div>
        </div>
    </>
}

const EUC = (props) => {
    return <>
        <div className={'Row'}>
            <span className={'Attributes'} >
                Replace high-risk desktop deployed tools with a centralised application
            </span>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <img src={SpreadsheetImage} alt={"Replace high-risk desktop deployed tools with a centralised application"}/>
                You're users face specific challenges and have unique needs. This could be a need to perform a specific
                reconciliation, or calculate a price or interest rate from proprietary inputs, or even automatically
                generate a list of trades from the results of a report such as end of day position flattening trades. Sometimes
                simple desktop applications are thrown together using Java or C#, or even Visual Basic. Or worse, a spreadsheet
                is used for the purpose. But these applications or spreadsheets are not well tested, provide no audit trail,
                and can often be modified by anyone without any access control - or are easily accidentally deleted and lost
                forever.  Edge is an enterprise solution. All changes are tracked, all access is permission controlled, and
                all functionality is rigorously tested. It won't get lost and changes cannot be made by unauthorised individuals,
                whether accidental or malicious.
            </div>
            <div className={'Column'}>
                <h1>Examples</h1>
                <ul>
                    <li>Replace Spreadsheets on trader desktops with <i>real</i> power tools
                        <ul>
                            <li>Pricing Spreadsheets</li>
                            <li>Risk Calculators</li>
                            <li>Interest rates</li>
                            <li>Report Generators</li>
                            <li>Bulk Trade Generators</li>
                            <li>etc.</li>
                        </ul>
                    </li>
                    <li>
                        Replace difficult to support desktop utilities
                        <ul>
                            <li>Unsupported C#, Java, or C++ desktop programs</li>
                        </ul>
                    </li>
                    <li>
                        Tools for reconciliation
                        <ul>
                            <li>Verify positions against daily trading activity</li>
                            <li>Compare prices or rates from multiple sources</li>
                            <li>Check daily activity against regulatory requirements</li>
                        </ul>
                    </li>
                    <li>
                        Know-Your-Client Database
                        <ul>
                            <li>Build a KYC database that is specific to your needs</li>
                            <li>Capture data from multiple sources and avoid custom entry</li>
                            <li>Secure data from unauthorized manipulation</li>
                            <li>Easy queries for things like sanctions or corporate policy imposed limits</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div className={'Row'}>
            <div style={{display:'flex', flexDirection:'Row', justifyContent:'space-between', width:'40vw'}}>
                <Button style={{width:'200px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>Gap-Filling Solutions</Button>
                <Button style={{width:'200px', marginLeft: '20px'}} onClick={() => props.api.moveTo(5,0)}><FiHome/> Use Case Summary</Button>
            </div>
            <div className={'Column'} >
                <div style={{ display:'flex', justifyContent:'end'}}>
                    <Button style={{width:'300px'}}  onClick={() => props.api.moveSlideRight()}>Infrastructure Enhancement <FiArrowRight/></Button>
                </div>
            </div>
        </div>
    </>
}

const Infrastructure = (props) => {
    return <>
        <div className={'Row'}>
            <span className={'Attributes'} >
                Improve reliability and capabilities of batch jobs
            </span>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <img src={InfrastructureImage} alt={"Improve reliability and capabilities of batch jobs"} />
                Your firm handles massive amounts of data and it needs to be moved. You need to transfer static data
                and market data between your systems - and those systems never speak the same language. Handling this
                movement of data reliably is a challenge. Simple shell scripts no longer cut it when you need assurance
                that files are delivered on time. Hudson's workflows can enable the building of complex networks of data
                extraction, transformation, and delivery to target system. You can create powerful dashboards to see the
                status of jobs at a glance, identify errors, and respond quickly. Hudson Edge is built for redundancy and
                reliability at every step and also provides the tools to change your jobs with unmatched agility.
            </div>
            <div className={'Column'}>
                <h1>Examples</h1>
                <ul>
                    <li>
                        Report and Datafile delivery
                        <ul>
                            <li>
                                Realtime monitoring of file and message delivery between systems
                            </li>
                            <li>
                                Automate retry policies, failure detection and recovery
                            </li>
                            <li>
                                Simple support dashboards and 'one-click' resend
                            </li>
                        </ul>
                    </li>

                    <li>
                        Centralised Data repositories
                        <ul>
                            <li>'Golden Source' repositories of trade, security, book, customer, or other arbitrary data</li>
                            <li>Transform and distribute data in any format over any transport layer with easy plugins</li>
                            <li>Create comprehensive management and manual entry screens</li>
                            <li>Easy accounting of data requests and tracking of usage</li>
                            <li>Share costs of external datasource feeds</li>
                        </ul>
                    </li>
                    <li>
                        Extract-Transform-Load
                        <ul>
                            <li>Create easy to understand workflows to extract data from one system and load it into another</li>
                            <li>Highly reusable plugins for any source and destination</li>
                        </ul>
                    </li>
                    <li>
                        Perform analytics in the background
                        <ul>
                            <li>Host machine learning models to identify data trends or potential problems</li>
                            <li>Aggregate data for cost-center billing</li>
                            <li>Build alerts on limits or specific situations occurring</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div className={'Row'}>
            <div style={{display:'flex', flexDirection:'Row', justifyContent:'space-between', width:'40vw'}}>
                <Button style={{width:'200px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>End User Computing</Button>
                <Button style={{width:'200px', marginLeft: '20px'}} onClick={() => props.api.moveTo(5,0)}><FiHome/> Use Case Summary</Button>
            </div>
            <div className={'Column'} >
                <div style={{ display:'flex', justifyContent:'end'}}>
                    <Button style={{width:'300px'}}  onClick={() => props.api.moveSlideRight()}>Trader Desktop<FiArrowRight/></Button>
                </div>
            </div>
        </div>
    </>
}

const TraderDesktop = (props) => {
    return <>
        <div className={'Row'}>
            <span className={'Attributes'} >
                Build high performance trader applications
            </span>
        </div>
        <div className={'Row'}>
            <div className={'Column'}>
                <img src={TraderImage} alt={"Build high performance trader applications"} />
                Your traders need tools that work for them, and many trader desktop applications just don't. Front office
                desktop systems are usually very big and cumbersome and slow to move with the times. With Hudson Edge
                you can have trader desktop applications that are designed and customised to your needs and requirements.
                You will not be forced to pay for countless features that you do not need, and will not have to sacrifice
                any functionality that you do require because the vendor doesn't support it. Hudson's back-end is incredibly
                flexible so you can build applications to handle any trade type or asset class. Hudson implements all of the
                core requirements for high availability, incident recovery, user access control, and audit trail tracking
                that is needed for a real trader desktop. A partnership with Hudson ensures that the software you use will
                always be the best fit for your needs.
            </div>
            <div className={'Column'}>
                <h1>Examples</h1>
                <ul>
                    <li>
                        Consolidated Data Views across multiple sources and products
                        <ul>
                            <li>Trades, Positions, Rates, Prices, etc</li>
                            <li>Powerful blotters with ability to sort, filter, and pivot data seamlessly</li>
                            <li>Build views, dashboards and reports to give traders unparalleled situational awareness</li>
                        </ul>
                    </li>
                    <li>
                        Cross asset trade capture
                        <ul>
                            <li>Design custom trade capture screens for any product</li>
                            <li>Create powerful defaulting rules and automation using visual tools</li>
                            <li>Integrated or external interfaces for pre-trade checks such as credit or regulatory compliance</li>
                            <li>Feed downstream execution, books and records, and settlement</li>
                            <li>Support complex lifecycles on products like REPO and SWAPS</li>
                        </ul>
                    </li>
                    <li>
                        Simple and automated quoting and negotiation
                        <ul>
                            <li>Powerful screens to aggregate decision making data at trader's fingertips</li>
                            <li>Visual tools to create rules to automatically handle requests, reducing trader workload</li>
                            <li>Route the deals to the right user with powerful request routing </li>
                        </ul>
                    </li>
                    <li>
                        Build complete systems for unique products or assets
                        <ul>
                            <li>Alternative assets trading</li>
                            <li>Build and manage custom product offerings</li>
                            <li>Generic capture of trade details for low volume or highly bespoke products</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div className={'Row'}>
            <div style={{display:'flex', flexDirection:'Row', justifyContent:'space-between', width:'40vw'}}>
                <Button style={{width:'300px'}} onClick={() => props.api.moveSlideLeft()}><FiArrowLeft/>Infrastructure Enhancement</Button>
                <Button style={{width:'200px', marginLeft: '20px'}} onClick={() => props.api.moveTo(5,0)}><FiHome/> Use Case Summary</Button>
            </div>
            <div className={'Column'} >
            </div>
        </div>
    </>
}

export {GapFilling, EUC, TraderDesktop, Infrastructure};
export default UseCases;