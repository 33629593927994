import {Button,Modal} from "react-bootstrap";
import logo from "./images/logo.svg";
import {useEffect, useRef, useState} from "react";
import {ContactForm} from "./ContactUs";

const FILE="HFWhitepaperECS";
const DATE="20210114";
const VER = "002";
const FN = 'files/' + FILE + '_revision' + DATE + '_' + VER + ".pdf";

const WhitePaperForm =(props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [downloadDiv, setDownloadDiv] = useState(<></>);
    const link = useRef();
    
    
    useEffect(() => {
        if (!isSubmitted) return;
        setDownloadDiv(
            <div id={'DownloadDiv'}>
                <h4> Thank-you for requesting our whitepaper. Your download should begin shortly. If it does not start
                    automatically, please <a ref={link} href={FN} target="_blank" rel="noreferrer"> click here </a>. </h4>
            </div>
        );

       setTimeout(() => {
            link.current.click()
        }, 1500); // click the link after 1.5 seconds.
    }, [isSubmitted]);

    return <Modal show={props.show} onHide={()=>props.onClose()} size={"lg"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>
                <img style={{marginRight:"15px"}} height={"50px"} src={logo} alt={"Logo"}/>
                Download our Whitepaper
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={isSubmitted?{visibility: "hidden", height:'0px'}:{}}>
                <h5>Please fill out the following form to download the Hudson Fintech Edge Platform whitepaper</h5>
                <span>Our whitepaper delves into some depth on the Entity Component System architecture and how Hudson Edge
                applies it to financial applications
                </span>
            </div>
            <div>
                {isSubmitted && downloadDiv}
                <div style={isSubmitted?{visibility: "hidden", height:'0px'}:{}}>
                    <ContactForm submitLabel = {'Submit And Download Whitepaper'} source='Whitepaper' onSubmit={()=> {
                      setIsSubmitted(true);
                    }}/>
                </div>
                <Modal.Footer>
                    <div style={{fontSize:'small'}}>
                        By submitting this form you consent to being contacted by Hudson Fintech Ltd. for marketing
                        purposes. Hudson Fintech will never sell your personal information or share it with any 3rd parties.
                        Please see our <a href="./hudson-terms.html" target="_blank">Privacy Policy</a> for more information.
                    </div>
                    <Button onClick={()=>props.onClose()}>Close</Button>
                </Modal.Footer>
            </div>
        </Modal.Body>
    </Modal>

}

export default WhitePaperForm;