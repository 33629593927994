import collaborationImage from "./images/collaboration.webp";
import {Button, Card} from "react-bootstrap";

const Products = (props) => {

    const contact = (topic) => {
        props.setFormMessage("I'm interested in learning more about the " + topic + " module.");
        props.api.moveTo(8);
    }

    return <div className={'Column'}>
        <div className={'Row'}>
            <div className={'Column'}>
                <h2 className={'section-heading'} >Product Offerings</h2>
                <span>All of our products are built on the Hudson Edge platform, giving unparalleled flexibility, stability,
                     and reliability with attractive licensing options
                </span>
            </div>
        </div>
        <div className={'Row'}>
            <Card style={{ width: '18rem' }}>
                <Card.Header>Hudson STP</Card.Header>
                <Card.Body>
                    <Card.Text>
                        The Hudson STP server is a flexible solution for linking your business together. With configurable
                        workflows you can connect your execution platforms to settlement systems with ease. The Hudson STP
                        module will let you transform message formats, add custom validations, route messages between gateways,
                        and provide detailed tracking of all messages. Save money by optimising your data flows and identifying
                        and correcting potential problems before it's too late with highly configurable exception queues and actions.
                    </Card.Text>
                </Card.Body>
                <Card.Footer><Button variant="primary" onClick={() => contact("Hudson STP")}>Learn More</Button></Card.Footer>
            </Card>

            <Card style={{ width: '18rem' }}>
                <Card.Header>Position Monitor</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Bring together an unobstructed global view of your firm's assets in a single place. Supporting a wide variety
                        of views including live intra-day movements and forward-looking ladders, the Hudson Position monitor
                        can aggregate your position data from any number of systems over multiple product types to present
                        a consolidated view along with powerful tools to 'slice and dice' your data anyway you see fit and
                        even set alerts to ensure nothing gets missed.
                    </Card.Text>
                </Card.Body>
                <Card.Footer><Button variant="primary" onClick={() => contact("Position Monitor")}>Learn More</Button></Card.Footer>
            </Card>

            <Card style={{ width: '18rem' }}>
                <Card.Header>Sanctions Monitor</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Load and view detailed information on global sanctions from official government lists. In the
                        current political climate it is becoming increasingly difficult to ensure that you're compliant
                        and up to date with all of the sanctioned individuals and organisations. The Hudson Sanctions
                        Monitor will load regularly updated sanctions lists and present an easy to search database by
                        region to identify if any of your clients are on any sanctions lists. Easily connect to your
                        KYC system, counterparty golden-source, or even pre-trade workflows for automated validations.
                    </Card.Text>
                </Card.Body>
                <Card.Footer><Button variant="primary" onClick={() => contact("Sanctions Monitor")}>Learn More</Button></Card.Footer>
            </Card>

            <Card style={{ width: '18rem' }}>
                <Card.Header>Sales Trader Workflow</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Accelerate your sales desk. Our sales trader workflow module can work with any asset to give your
                        sales team a simple unified experience across all product offerings. Hudson can integrate with
                        your current platforms to present a powerful sales workflow with deal routing and multi-stage
                        negotiation. With total customisation around workflow stages, limits, and entry screens your whole
                        business will be more efficient with fewer overheads.
                    </Card.Text>
                </Card.Body>
                <Card.Footer><Button variant="primary" onClick={() => contact("Sales Trader Workflow")}>Learn More</Button></Card.Footer>
            </Card>

            <Card style={{ width: '18rem' }}>
                <Card.Header>Repo Trade Capture</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Hudson's REPO Trade capture is the perfect low-cost solution for enabling repo trading in your business.
                        Manage the full lifecycle of repo trades including term and open trades with complex intralife events
                        and powerful analytics options. The Hudson Repo solution supports specials and general collateral trading
                        and can integrate with any market gateway provider or settlement system you require. The flexible
                        nature of Hudson's platform makes our repo offering an ideal fit for organisations that need a high
                        degree of automation or have unusual internal flows or processes.
                    </Card.Text>
                </Card.Body>
                <Card.Footer><Button variant="primary" onClick={() => contact("Repo")}>Learn More</Button></Card.Footer>
            </Card>
        </div>

    </div>
}
export default Products;